import { callHookShorthand } from '@/mixins'
import { MainPageRouterName } from '@/router/Routes'

import * as _permissions from '@/helpers/ability/permissions'

export default {
  methods: {
    hookLoginSuccess_navigation() {
      this.defineRouteGuardWatch()

      this.handleRecoveryRedirectPath()
    },

    handleRecoveryRedirectPath() {
      const from = sessionStorage.getItem('interface-recovery--redirectPath')
      if (!from && this.$route.name === 'Login') {
        this.goNextRoutePossible(MainPageRouterName)
        return
      }

      const { to, toQuery } = JSON.parse(from || '{}')

      sessionStorage.removeItem('interface-recovery--redirectPath')

      this.$router.replace({
        ...to,
        query: toQuery,
      })
    },

    defineRouteGuardWatch() {
      return this.$watch('$route.matched', this.handleRouteGuard, {
        immediate: true,
      })
    },

    async handleRouteGuard(matched) {
      const canPagePermissions = this.calcRoutePermissions(matched)
      const shouldGuardRoute = Object.keys(canPagePermissions).length
      const hasPermission = Object.values(canPagePermissions).every(
        permission => permission === true
      )

      if (
        (shouldGuardRoute && !hasPermission) ||
        this.$route.name === MainPageRouterName
      ) {
        this.goNextRoutePossible(MainPageRouterName)
      }

      return shouldGuardRoute && !hasPermission
    },

    /**
     * @param {Array} matched
     */
    calcRoutePermissions(matched) {
      const requiresPermissions = Array.from(
        new Set(matched.flatMap(record => record?.meta?.requiresPermission))
      ).filter(Boolean)

      const canPagePermissions = requiresPermissions.reduce(
        (obj, permission) => {
          const canPermission = this.$can('access', permission)
          return { ...obj, [permission]: canPermission }
        },
        {}
      )

      return canPagePermissions
    },

    /**
     * @param {String} RouteName
     */
    goNextRoutePossible(RouteName) {
      const PageRouter = this.$router.options.routes.find(
        r => r.name === RouteName
      )

      const canAccess = routePermission => {
        if (typeof routePermission !== 'boolean') {
          return Object.values(routePermission).some(childPermission =>
            canAccess(childPermission)
          )
        }

        return routePermission
      }

      const getRoutePermissions = Route =>
        Route?.children?.reduce((obj, page) => {
          if (page.path.includes(':')) {
            return { ...obj }
          }

          const matched = [Route, page]
          let hasPermission = Object.values(
            this.calcRoutePermissions(matched)
          ).every(permission => permission === true)

          if (page?.children) {
            const children = getRoutePermissions(page)

            if (Object.keys(children).length) {
              const hasPermissionChildren = Object.values(children).some(
                permission => canAccess(permission)
              )

              hasPermission = hasPermissionChildren ? children : false
            }
          }

          return { ...obj, [page.name]: hasPermission }
        }, {}) || {}

      const PageChilds = getRoutePermissions(PageRouter)

      let nextRoutePossible
      const getNextRoutePossible = Routes =>
        Object.keys(Routes).some(permission => {
          if (typeof Routes[permission] !== 'boolean') {
            return getNextRoutePossible(Routes[permission])
          }

          if (Routes[permission]) {
            nextRoutePossible = permission
          }

          return Routes[permission]
        })

      getNextRoutePossible(PageChilds)

      if (!nextRoutePossible) {
        return this.hookGuardFail()
      }

      this.handleNextPossibleRouter(nextRoutePossible)
    },

    isPreventSameRouter(nextRoutePossible) {
      return this.$route.matched.some(r => r.name === nextRoutePossible)
    },

    handleNextPossibleRouter(nextRoutePossible) {
      setTimeout(() => {
        if (this.isPreventSameRouter(nextRoutePossible)) {
          this.hookGuardSuccess()
          return
        }

        this.$router.push(
          { name: nextRoutePossible },
          () => {
            this.hookGuardSuccess()
          },
          () => {}
        )
      }, 0)
    },

    hookGuardFail(error) {
      callHookShorthand(this, 'hookGuardFail_', error)
    },
    hookGuardSuccess(data) {
      callHookShorthand(this, 'hookGuardSuccess_', data)
    },

    hookGuardFail_profile() {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        throw new Error(
          '[NavigationGuard] hookGuardFail_profile - Sem permissão para acessar outras rotas',
          this.$can
        )
      }

      if (!this.$can('access', _permissions.profile)) return

      window.location.assign('/profile')
    },
  },
}
