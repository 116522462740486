import { callHookShorthand } from '@/mixins'

import { projectAuth } from '@/firebase/config'

import { definePermissions } from '@/helpers/ability'

export default {
  provide() {
    return {
      handleLogout: this.handleLogout,
    }
  },

  created() {
    this.onAuthStateChangedByLogin()
  },

  methods: {
    hookGuardFail_logout() {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        throw new Error(
          '[Login] hookGuardFail_logout - Não tem permissão para acessar nenhuma rota',
          this.$can
        )
      }

      this.handleLogout().then(() => {
        this.callLoadingWrapperMethods('customErrorMessage', {
          text: 'pageLogin.errorPermission.text',
        })
      })
    },

    async handleLogout() {
      return new Promise(resolve => {
        this.handleUserNotFound()

        projectAuth.signOut().then(() => {
          if (!window.location.href.includes('login')) {
            this.$router.push({ name: 'Login' })
          }

          resolve()
        })
      })
    },

    onAuthStateChangedByLogin() {
      // wait for login
      this.$store.dispatch('signedInLogout')

      projectAuth.onAuthStateChanged(this.onAuthStateChangedNextOrObserver)
    },

    /**
     * @param {import('@firebase/auth').User} User
     */
    onAuthStateChangedNextOrObserver(User) {
      if (!User) {
        this.handleUserNotFound()
        return
      }

      this.handleAuthenticated({ refresh: true })
    },

    handleUserNotFound() {
      this.$store.dispatch('signedInLogout')
      this.$store.dispatch('currentUser/logoutAccount')

      this.hookUserNotFound()
    },

    async handleAuthenticated(payload) {
      this.callLoadingWrapperMethods('setLoading', true)

      return this.$store
        .dispatch('currentUser/getAccountMe', payload)
        .then(data => {
          // definePermissions([]) // @TODO Em Branco
          definePermissions(data.permissions)

          this.$store.dispatch('signedInLogin')

          this.hookLoginSuccess(data)
        })
        .catch(() => {
          this.callLoadingWrapperMethods('setLoading', false)
          this.callLoadingWrapperMethods('customErrorMessage')

          // User is signed out.
          this.handleLogout()
        })
    },

    callLoadingWrapperMethods(methodName, ...payload) {
      this.$nextTick(() => {
        if (!window.location.href.includes('login')) return

        const method = this.$refs?.PageView?.[methodName]
        if (typeof method !== 'function') return

        method(...payload)
      })
    },

    hookUserNotFound(data) {
      callHookShorthand(this, 'hookUserNotFound_', data)
    },
    hookLoginSuccess(data) {
      callHookShorthand(this, 'hookLoginSuccess_', data)
    },
  },
}
