import Vue from 'vue'

export const RequestModule = {
  namespaced: true,

  state: () => ({
    requests: {},
  }),

  getters: {
    getRequests: state => state.requests,
  },

  actions: {
    setRequest({ commit }, payload) {
      commit('SET_REQUEST', payload)
    },
  },

  mutations: {
    SET_REQUEST(state, payload) {
      Vue.set(state.requests, payload.key, payload.value)
    },
  },
}
