import Vue from 'vue'

export const ReportModule = {
  namespaced: true,

  state: () => ({
    id: null,
    subtitle: '',
    isRespondent: true,
    isOwner: false,
    canAccess: false,
    groupID: null,
    favorability: false,
    apiResults: {
      data: null,
      loading: false,
    },
    question: null,
  }),

  getters: {
    getSurveyID: state => state.id,
    getSubtitle: state => state.subtitle,
    getIsRespondent: state => state.isRespondent,
    getIsOwner: state => state.isOwner,
    getCanAccess: state => state.canAccess,
    getGroupID: state => state.groupID,
    getFavorability: state => state.favorability,
    getApiResults: state => state.apiResults,
    getQuestion: state => state.question,
  },

  actions: {
    setSubtitle({ commit }, payload) {
      commit('SET_SUBTITLE', payload)
    },
    setIsRespondent({ commit }, payload) {
      commit('SET_IS_RESPONDENT', payload)
    },
    setIsOwner({ commit }, payload) {
      commit('SET_IS_OWNER', payload)
    },
    setCanAccess({ commit }, payload) {
      commit('SET_CAN_ACCESS', payload)
    },
    setGroupID({ commit }, payload) {
      commit('SET_GROUP_ID', payload)
    },
    setFavorability({ commit }, payload) {
      commit('SET_FAVORABILITY', payload)
    },
    setSurveyID({ commit }, payload) {
      commit('SET_SURVEY_ID', payload)
    },
    setApiResults({ commit }, payload) {
      commit('SET_API_RESULTS', payload)
    },
    setQuestion({ commit }, payload) {
      commit('SET_QUESTION', payload)
    },
  },

  mutations: {
    SET_SUBTITLE(state, payload) {
      Vue.set(state, 'subtitle', payload)
    },
    SET_IS_RESPONDENT(state, payload) {
      Vue.set(state, 'isRespondent', payload)
    },
    SET_IS_OWNER(state, payload) {
      Vue.set(state, 'isOwner', payload)
    },
    SET_CAN_ACCESS(state, payload) {
      Vue.set(state, 'canAccess', payload)
    },
    SET_GROUP_ID(state, payload) {
      Vue.set(state, 'groupID', payload)
    },
    SET_FAVORABILITY(state, payload) {
      Vue.set(state, 'favorability', payload)
    },
    SET_SURVEY_ID(state, payload) {
      Vue.set(state, 'id', payload)
    },
    SET_API_RESULTS(state, payload) {
      Vue.set(state, 'apiResults', payload)
    },
    SET_QUESTION(state, payload) {
      Vue.set(state, 'question', payload)
    },
  },
}
